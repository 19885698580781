import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { logoutHandler } from '../services/auth';
import ImgNextGen from './ImgNextGen';
import config from './config';
import { emailGame } from './actions/game-actions';

class GetEmailCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiverEmail: '',
      gameVersionId: '',
      topText: '',
      thumbnailUrl: '',
      thumbnailUrlWebp: '',
    };
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentWillMount() {
    this.setState({
      gameVersionId: this.props.gameVersionId,
      receiverEmail: this.props.receiverEmail,
    });
    this.setTopText();
  }

  setTopText() {
    let text;
    switch (this.props.gameVersionId) {
      case '1':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Christmas Video Game Starring You!` : 'You\'ve been sent a Christmas Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}to-birthday-and-beyond.png`,
          thumbnailUrlWebp: `${config.img}to-birthday-and-beyond.webp`,
        });
        break;
      case '4':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Christmas Video Game Starring You!` : 'You\'ve been sent a Christmas Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}workshop-dash.png`,
          thumbnailUrlWebp: `${config.img}workshop-dash.webp`,
        });
        break;
      case '9':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Christmas Video Game Starring You!` : 'You\'ve been sent a Christmas Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}christmas-slider.png`,
          thumbnailUrlWebp: `${config.img}christmas-slider.webp`,
        });
        break;
      case '2':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Birthday Video Game Starring You!` : 'You\'ve been sent a Birthday Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}to-birthday-and-beyond.png`,
          thumbnailUrlWebp: `${config.img}to-birthday-and-beyond.webp`,
        });
        break;
      case '3':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Birthday Video Game Starring You!` : 'You\'ve been sent a Birthday Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}to-birthday-and-beyond.png`,
          thumbnailUrlWebp: `${config.img}to-birthday-and-beyond.webp`,
        });
        break;
      case '8':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Birthday Video Game Starring You!` : 'You\'ve been sent a Birthday Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}birthday-derby.png`,
          thumbnailUrlWebp: `${config.img}birthday-derby.webp`,
        });
        break;
      case '10':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Birthday Video Game Starring You!` : 'You\'ve been sent a Birthday Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}beach-birthday.png`,
          thumbnailUrlWebp: `${config.img}beach-birthday.webp`,
        });
        break;
      case '12':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Birthday Video Game Starring You!` : 'You\'ve been sent a Birthday Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}princess-perils.png`,
          thumbnailUrlWebp: `${config.img}princess-perils.webp`,
        });
        break;
      case '6':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a New Years Video Game Starring You!` : 'You\'ve been sent a New Years Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}beat-the-ball-drop.png`,
          thumbnailUrlWebp: `${config.img}beat-the-ball-drop.webp`,
        });
        break;
      case '7':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Valentines Day Video Game Starring You!` : 'You\'ve been sent a Valentines Day Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}cupids-gauntlet.png`,
          thumbnailUrlWebp: `${config.img}cupids-gauntlet.webp`,
        });
        break;
      case '11':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Valentines Day Video Game Starring You!` : 'You\'ve been sent a Valentines Day Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}valentines-date-dash.png`,
          thumbnailUrlWebp: `${config.img}valentines-date-dash.webp`,
        });
        break;
      case '5':
        text = localStorage.getItem('name') ? `${localStorage.getItem('name')} Has Sent you a Custom Video Game Starring You!` : 'You\'ve been sent a Custom Video Game Starring You!';
        this.setState({
          topText: text,
          thumbnailUrl: `${config.img}adventures-of-you.png`,
          thumbnailUrlWebp: `${config.img}adventures-of-you.webp`,
        });
        break;
      default:
        this.setState({
          topText: 'HUGE ERROR!!',
          thumbnailUrl: `${config.img}adventures-of-you.png`,
          thumbnailUrlWebp: `${config.img}adventures-of-you.webp`,
        });
    }
  }

  sendEmail() {
    if (!this.props.receiverEmail) {
      this.props.emailError();
      return;
    }
    emailGame(this.state.topText, this.props.receiverEmail, this.props.gameLink, this.props.gameVersionId, this.state.thumbnailUrl);
  }

  render() {
    return (
      <div>
        <div>
          <div style={{ padding: '2rem' }}>{this.state.topText}</div>
          <ImgNextGen width="40%" margin="auto" fallback={this.state.thumbnailUrl} srcWebp={this.state.thumbnailUrlWebp} />
          <br />
          <a href="#">Click Here</a>
          {' '}
          To Check Out Your Custom Game
        </div>
        <br />
        <button className="main-nav-link" onClick={this.sendEmail}>Send This Email</button>
      </div>
    );
  }
}

export default GetEmailCopy;
