import { gameValues } from './render-game';

export function gameOver(setGameValues) {
  gameValues.coins = 0;
  gameValues.lives = 5;
  gameValues.health = 6;
  gameValues.respawning = false;
  gameValues.dying = false;
  setGameValues({
    gameOver: true,
    menuOpen: true,
  });
}

export function respawn(setGameValues) {
  if (!gameValues.respawning) {
    gameValues.lives -= 1;
    gameValues.respawning = true;
    gameValues.y = 2000;
    setTimeout(() => {
      if (gameValues.lives >= 0) {
        gameValues.uICtx.clearRect(0, 0, 300, 300);
        gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 20, 20, 35, 35);
        gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 60, 20, 35, 35);
        gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 100, 20, 35, 35);
        gameValues.uICtx.drawImage(gameValues.gameSprites['coin-full'], 160, 17, 37, 37);
        gameValues.uICtx.fillText(`x ${gameValues.coins}`, 204, 45);
        gameValues.uICtx.clearRect(gameValues.canvas.width - 180, 0, 180, 180);
        gameValues.uICtx.drawImage(
          gameValues.gameSprites.heroImage,
          gameValues.canvas.width - 180,
          7,
          100,
          100,
        );
        gameValues.uICtx.fillText(`x ${gameValues.lives}`, gameValues.canvas.width - 115, 45);
        gameValues.x = gameValues.respawnX;
        gameValues.y = gameValues.respawnY;
        gameValues.cameraX = gameValues.respawnX - gameValues.canvas.width / 2;
        gameValues.cameraY = gameValues.respawnY - gameValues.canvas.height / 3;
        gameValues.jumpSpeed = 10;
        gameValues.health = 6;
        gameValues.respawning = false;
        gameValues.dying = false;
      } else {
        gameValues.levelMusic.current.pause();
        // levelMusic.current.pause()
        gameOver(setGameValues);
        // Handle Game Over
      }
    }, 1500);
  }
}

export function groundCheck(characterX, characterY, characterJumpSpeed, characterHeight, cb) {
  // console.log(characterX, characterY, characterJumpSpeed, characterHeight)
  let grounded = false;
  // THIS CODE MAY BE SLOWING THINGS DOWN A LOT
  gameValues.tileSet.forEach((platform) => {
    if (
      characterX > (platform.left - 60)
      && characterX < (platform.left + ((platform.count - 1) * 80) + 60)
      && characterY + characterJumpSpeed >= (platform.top - characterHeight)
      && characterY <= (platform.top - characterHeight)) {
      // characterY = (platform.top - 110);
      cb(platform.top)
      characterJumpSpeed = 10;
      grounded = true;
    }
  });
  if (gameValues.groundLikeObjectsInFrame.length > 0) {
    gameValues.groundLikeObjectsInFrame.forEach((platform) => {
      if (platform.object === 'moving-platforms') {
        platform.platforms.forEach((movingPlatform) => {
          if (
            characterX > (movingPlatform.x - 60)
            && characterX < (movingPlatform.x + ((movingPlatform.count - 1) * 80) + 60)
            && characterY + characterJumpSpeed >= (movingPlatform.y - characterHeight)
            && characterY <= (movingPlatform.y - characterHeight) && characterJumpSpeed >= 0) {
            // characterY = (movingPlatform.y - 110);
            cb(movingPlatform.y)
            characterJumpSpeed = 10;
            grounded = true;
            if (!gameValues.stuckToPlatform) {
              gameValues.stuckToPlatform = movingPlatform;
            }
          }
        });
      } else if (
        characterX > (platform.x - 60)
        && characterX < (platform.x + ((platform.count - 1) * 80) + 60)
        && characterY + characterJumpSpeed >= (platform.y - characterHeight)
        && characterY <= (platform.y - characterHeight)
        && characterJumpSpeed >= 0) {
        // characterY = (platform.y - 110);
        cb(platform.y)
        characterJumpSpeed = 10;
        grounded = true;
        if (!gameValues.stuckToPlatform) {
          gameValues.stuckToPlatform = platform;
        }
      }
    });
  }
  return grounded;
}

export function updateUI(setGameValues) {
  let firstHeart; let secondHeart; let
    thirdHeart;
  switch (gameValues.health) {
    case 6:
      firstHeart = gameValues.gameSprites['heart-full'];
      secondHeart = gameValues.gameSprites['heart-full'];
      thirdHeart = gameValues.gameSprites['heart-full'];
      break;
    case 5:
      firstHeart = gameValues.gameSprites['heart-full'];
      secondHeart = gameValues.gameSprites['heart-full'];
      thirdHeart = gameValues.gameSprites['heart-half'];
      break;
    case 4:
      firstHeart = gameValues.gameSprites['heart-full'];
      secondHeart = gameValues.gameSprites['heart-full'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
      break;
    case 3:
      firstHeart = gameValues.gameSprites['heart-full'];
      secondHeart = gameValues.gameSprites['heart-half'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
      break;
    case 2:
      firstHeart = gameValues.gameSprites['heart-full'];
      secondHeart = gameValues.gameSprites['heart-empty'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
      break;
    case 1:
      firstHeart = gameValues.gameSprites['heart-half'];
      secondHeart = gameValues.gameSprites['heart-empty'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
      break;
    case 0:
      firstHeart = gameValues.gameSprites['heart-empty'];
      secondHeart = gameValues.gameSprites['heart-empty'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
      respawn(setGameValues);
      break;
    default:
      firstHeart = gameValues.gameSprites['heart-empty'];
      secondHeart = gameValues.gameSprites['heart-empty'];
      thirdHeart = gameValues.gameSprites['heart-empty'];
  }
  // all drawing should happen in render section. remove from here!
  gameValues.uICtx.clearRect(0, 0, 300, 300);
  gameValues.uICtx.drawImage(firstHeart, 20, 20, 35, 35);
  gameValues.uICtx.drawImage(thirdHeart, 100, 20, 35, 35);
  gameValues.uICtx.drawImage(secondHeart, 60, 20, 35, 35);
  gameValues.uICtx.drawImage(gameValues.gameSprites['coin-full'], 160, 17, 37, 37);
  gameValues.uICtx.fillText(`x ${gameValues.coins}`, 204, 45);
}

export async function hurtPlayer(damage, setGameValues) {
  if (gameValues.health - damage <= 0) {
    // gameOver()
    // Handle Game Over Here
  }
  if (!gameValues.invincibleOnKnockback) {
    gameValues.health -= damage;
    gameValues.knockback = true;
    gameValues.isJumping = true;
    gameValues.invincibleOnKnockback = true;
    updateUI(setGameValues);
    setTimeout(() => {
      gameValues.knockback = false;
      gameValues.invincibleOnKnockback = false;
    }, 700);
  }
}
