import { navigate } from 'gatsby';
import config from '../config';

export function updateProfile(formData, name, email, redirect) {
  fetch(`${config.backend}/client-image`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((fileResData) => {
      const imageUrls = fileResData.filePaths;
      return fetch(`${config.backend}/user/update-user-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
          profileImage: imageUrls[0],
          newName: name,
          newEmail: email,
        }),
      });
    })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors && resData.errors[0].status === 422) {
        throw new Error(
          "Validation failed. Make sure the email address isn't used yet!",
        );
      }
      if (resData.errors) {
        throw new Error('User login failed!');
      }
      if (typeof window !== 'undefined') {
        const profileImage = (resData.user.avatar ? `${config.backend}/${resData.user.avatar}` : 'https://pixopixa.cyou/images/profile-placeholder.png');
        localStorage.setItem('profile-url', profileImage);
        navigate(redirect);
      }
    });
}

export function getClientGames() {
  fetch(`${config.backend}/user/get-client-games`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      token: localStorage.getItem('token') ? localStorage.getItem('token') : null, // Do we ever need this, if it's in the header???
    }),
  })
    .then((res) => res.json())
    .then((resData) => resData);
}

export async function findPlanEndDate() {
  const res = await fetch(`${config.backend}/user/find-plan-end-date`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      token: localStorage.getItem('token') ? localStorage.getItem('token') : null, // Do we ever need this, if it's in the header???
    }),
  });
  const resData = await res.json();
  return resData;
}
