// import React, { Component } from 'react'
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import OrderForm from "../components/OrderForm"
//
// class Cart extends Component {
//   state = {
//     stripeToken: '',
//   };
//
//   componentDidMount() {
//     console.log(this)
//   }
//
//   render() {
//     return (
//       <div>
//         <StripeProvider apiKey="pk_test_51GrALcKo3kWsL7q1vKEwC849rkp8SOJo5m0qi49bn4fggWxmG5z7eYKkkea57egbIIM5YKpguq7Ie6HblAwn7Ju800Dj3aMEoH">
//           <Elements>
//             <OrderForm stripe={this.props.stripe} clientId={this.props.location.state.id} clientEmail={this.props.location.state.email}/>
//           </Elements>
//         </StripeProvider>
//       </div>
//     )
//   }
// }
//
// export default Cart

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import OrderForm from './OrderForm';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe('pk_test_51GrALcKo3kWsL7q1vKEwC849rkp8SOJo5m0qi49bn4fggWxmG5z7eYKkkea57egbIIM5YKpguq7Ie6HblAwn7Ju800Dj3aMEoH');

export default function CheckoutSingle(props) {
  let email;
  if (typeof window !== 'undefined') {
    email = localStorage.getItem('email');
  }
  return (
    <div>
      <Elements stripe={promise}>
        <OrderForm email={email} />
      </Elements>
    </div>
  );
}
