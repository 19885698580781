import React, { Component } from 'react'
import Layout from "../components/layout-minimal"
import { navigate } from "gatsby"
import Input from '../components/input';
import { v1 as uuidv1 } from 'uuid';
import { validateEmail } from "../util/validators.js"
import { setAutoLogout } from "../services/auth"
import Loader from 'react-loader-spinner'
import ImgNextGen from "../components/ImgNextGen"
import VillianImage from "../../public/images/space-villian.png"
import Image2 from "../../public/images/theif.png"
import Step from "../../public/images/step-3.png"
import Profile from "../../public/images/profile-placeholder.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { updateProfile, findPlanEndDate } from "./actions/profile-actions"
import { timeConverter } from "../util/date-util"
import { config } from "./config"

class SignUp extends Component {
  state = {
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    editName: false,
    editEmail: false,
    sliderVisible: false,
    uncroppedImage: null,
    profileURL: null,
    profileSliderValue: 1,
    profileRotaterValue: 0,
    faceOnly: null,
    profileDrag: false,
    mouseX: 0,
    mouseY: 0,
    profileImageOffsetX: 0,
    profileImageOffsetY: 0,
    profileImageOffsetXOld: 0,
    profileImageOffsetYOld: 0,
    profileImageOffsetXTotal: 0,
    profileImageOffsetYTotal: 0,
    endDateOfPlan: null,
    redirect: "/secure/profile",
  };

  async componentDidMount() {
    var canvas = document.getElementById('profile-cropper');
    // canvas.width = 578;
    // canvas.height = 660;
    var ctx = canvas.getContext('2d');
    var imageObj = new Image();
    imageObj.onload = (e) => {
      ctx.drawImage(imageObj, 0, 0, canvas.width, canvas.height)
    }
    imageObj.src = localStorage.getItem('profile-url') ? localStorage.getItem('profile-url') : Profile
    let endDate = await findPlanEndDate()
    let subEnd;
    if (endDate && endDate.subscriptionEnd) {
      subEnd = endDate.subscriptionEnd
    }
    let formatedTime = timeConverter(subEnd)
    console.log(formatedTime)
    this.setState({
      endDateOfPlan: formatedTime,
    })
  }

  changeHandler = async (event, authData) => {
    event.preventDefault();
    this.setState({loading: true})
    if (!this.validateForm()) {
      window.scrollTo(0, 0);
      this.setState({loading: false})
      return
    }
    const newId = uuidv1();
    try {
      let response = await fetch(`${config.backend}/user/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: newId,
        email: authData.email,
        name: authData.name,
        password: authData.password
      })
    })
    let res = await response.json();
    if (res.status === 422) {
      this.setState({failedMessage: res.message})
      window.scrollTo(0, 0);
      this.setState({loading: false})
      throw new Error("Validation failed. Make sure this email hasn't been used yet.")
    }
    localStorage.setItem('token', res.token);
    localStorage.setItem('userId', res.id);
    localStorage.setItem('name', res.name);
    localStorage.setItem('email', res.email);
    const remainingMilliseconds = 365 * 24 * 60 * 60 * 1000;
    const expiryDate = new Date(
      new Date().getTime() + remainingMilliseconds
    );
    localStorage.setItem('expiryDate', expiryDate.toISOString());
    setAutoLogout(remainingMilliseconds);

    this.setState({loading: false})

    navigate('/secure/checkout', {
          state: {
            customer: res.customer,
            id: res.id,
            email: res.email
          }
        });
      }
      catch(err) {
        console.log(err);
    }
  };

  inputChangeHandler = (input, value) => {
    this.setState({
        [input]: value
      })
    };

    handleImageSelect(image) {
      console.log(image)
      let overlayCanvas = document.getElementById('profile-cropper-overlay')
      overlayCanvas.addEventListener('mousedown', (e) => {
        this.setState({
          profileDrag: true,
          mouseX: e.clientX,
          mouseY: e.clientY
        })
      })
      overlayCanvas.addEventListener('mouseup', () => {
        this.setState({
          profileDrag: false,
          profileImageOffsetX: 0,
          profileImageOffsetY: 0,
          profileImageOffsetXOld: 0,
          profileImageOffsetYOld: 0,
          profileImageOffsetXTotal: this.state.profileImageOffsetXTotal + this.state.profileImageOffsetX,
          profileImageOffsetYTotal: this.state.profileImageOffsetYTotal + this.state.profileImageOffsetY,
        })
      })
      overlayCanvas.addEventListener('mouseout', () => {
        this.setState({
          profileDrag: false,
          profileImageOffsetX: 0,
          profileImageOffsetY: 0,
          profileImageOffsetXOld: 0,
          profileImageOffsetYOld: 0,
          profileImageOffsetXTotal: this.state.profileImageOffsetXTotal + this.state.profileImageOffsetX,
          profileImageOffsetYTotal: this.state.profileImageOffsetYTotal + this.state.profileImageOffsetY,
        })
      })
      overlayCanvas.addEventListener('mousemove', (e) => {
        if (!this.state.profileDrag) return

        this.setState({
          profileImageOffsetXOld: this.state.profileImageOffsetX,
          profileImageOffsetYOld: this.state.profileImageOffsetY,
          profileImageOffsetX: e.clientX - this.state.mouseX,
          profileImageOffsetY: e.clientY - this.state.mouseY,
        }, () => {
          this.sliderChangeHandler(this.state.profileSliderValue)
        })
      })
      overlayCanvas.addEventListener('wheel', (e) => {
          e.preventDefault()
          let profileSliderValue = e.deltaY > 0 ? this.state.profileSliderValue + 0.01 : this.state.profileSliderValue - 0.01
          this.sliderChangeHandler(profileSliderValue)
        }
      )
      window.URL = window.URL || window.webkitURL
      let fileURL = window.URL.createObjectURL(image)
      console.log(fileURL)
      this.setState({
          uncroppedImage: image,
          profileURL: fileURL,
        }, () => {
          this.appendCharacterToCanvas()
        }
      )
    }

    appendCharacterToCanvas() {
      this.setState({sliderVisible:  true})
      var mainCanvas = document.getElementById('profile-cropper');
      var overlayCanvas = document.getElementById('profile-cropper-overlay');
      var context = mainCanvas.getContext('2d');
      var imageObj = new Image();
      imageObj.onload = function(sliderValue) {
        // draw cropped image
        var destX = 0;
        var destY = 0;
        mainCanvas.width = 200;
        mainCanvas.height = 200;
        var destWidth = imageObj.width;
        var destHeight = imageObj.height;
        context.drawImage(imageObj, destX, destY, destWidth, destHeight);
        overlayCanvas.width = 200;
        overlayCanvas.height = 200;
        var context2 = overlayCanvas.getContext('2d');
        context2.beginPath();
        context2.rect(0, 0, overlayCanvas.width, overlayCanvas.height);
        context2.fillStyle = "rgba(0, 0, 0, 0.8)";//rgba(10,10,10,0.7);
        context2.fill();

        // begin custom shape
        context2.beginPath();
        context2.arc(100, 100, 100, 0, 2 * Math.PI);
        context2.stroke();
        context2.closePath();
        context2.lineWidth = 2;
        context2.strokeStyle = '#F4F4F4';
        context2.stroke();
        context2.globalCompositeOperation = 'destination-out';
        context2.fillStyle = "rgba(255, 255, 255, 1)";
        context2.fill()
      };
      imageObj.src = this.state.profileURL;
    }

    changeProfileImage(e) {
      e.preventDefault()
      document.getElementById("profile-image-input").click()
    }

    // NEW STUFF OZGE
    sliderChangeHandler(value, isRotater = false) {
      var rotaterValue = 0;
      let currentRotater2
      let translateX
      let translateY
      var scalingFactor
      var mainCanvas
      mainCanvas = document.getElementById('profile-cropper');
      if (isRotater) {
        rotaterValue = parseFloat(value) > this.state.profileRotaterValue ? 1 : -1
      }
      currentRotater2 = this.state.profileRotaterValue + rotaterValue
      translateX = this.state.profileImageOffsetX - this.state.profileImageOffsetXOld
      translateY = this.state.profileImageOffsetY - this.state.profileImageOffsetYOld
      scalingFactor = isRotater === false ? parseFloat(value) : this.state.profileSliderValue;
      let currentRotater = this.state.profileRotaterValue + rotaterValue
      if (!isRotater) {
        this.setState({
          profileSliderValue: parseFloat(value)
        })
      } else {
        this.setState({
          profileRotaterValue: currentRotater
        })
      }
      var context = mainCanvas.getContext('2d');
      var imageObj = new Image();
      imageObj.onload = (e) => {
        context.clearRect(-200, -200, 2.5*mainCanvas.width, 2.5*mainCanvas.height)
        var destWidth = imageObj.width * scalingFactor;
        var destHeight = imageObj.height * scalingFactor;
        context.rotate(currentRotater2 * Math.PI / 180)
        context.drawImage(imageObj, 0, 0, destWidth, destHeight);
        context.rotate(-currentRotater2 * Math.PI / 180)
        context.translate(translateX, translateY)
      }
      imageObj.src = this.state.profileURL;
    }

      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
      }

      submitHandler() {
          if (!this.state.profileURL) {
            this.submitGameHandler()
            return;
          }
          var hiddenCanvas = document.getElementById('hidden-canvas');
          hiddenCanvas.width = 200;
          hiddenCanvas.height = 200;
          var context = hiddenCanvas.getContext('2d');
          context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height)
          var imageObj = new Image();
          var scalingFactor = this.state.profileSliderValue
          var xOffset = this.state.profileImageOffsetX + this.state.profileImageOffsetXTotal
          var yOffset = this.state.profileImageOffsetY + this.state.profileImageOffsetYTotal
          let saveCanvasHandler = this.saveCanvasHandler
          imageObj.onload = (e) => {
            var destX = 0 + xOffset;
            var destY = 0 + yOffset;
            var destWidth = imageObj.width * scalingFactor;
            var destHeight = imageObj.height * scalingFactor;
            /// Image 2
            context.drawImage(imageObj, destX, destY, destWidth, destHeight);
            context.beginPath();
            context.rect(0, 0, imageObj.width, imageObj.height);

            // begin custom shape
            context.beginPath();
            context.arc(100, 100, 100, 0, 2 * Math.PI);
            // complete custom shape
            context.closePath();
            context.globalCompositeOperation = 'destination-in';
            context.fillStyle = "rgba(255, 255, 255, 1)";
            context.fill()
            context.globalCompositeOperation = 'source-over';
            let faceOnly = hiddenCanvas.toDataURL();
            this.submitGameHandler(faceOnly)
          }
          imageObj.src = this.state.profileURL;
        }

        async submitGameHandler(faceOnly) {
          const formData = new FormData();
          if (faceOnly) {
            formData.append('gameId', localStorage.getItem('userId'))
            formData.append('images', this.dataURLtoBlob(faceOnly), "profileImage");
          }
          await updateProfile(formData, this.state.name, this.state.email, this.state.redirect);
        }
    // NEW STUFF OZGE

    render() {
      return(
      <Layout>
        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#03cea4" }}>
          <div style={{width: "20%"}}></div>
          <form style={{ minHeight: '470px', width: "60%" }} onSubmit={e => this.signupHandler(e, this.state)}>
            <div style={{ textAlign: "center", backgroundColor: "#ffffff", borderRadius: "20px", boxShadow: "0px 8px 18px 5px rgba(0,0,0,0.6)" }}>
              <br />
              <h1>User Profile</h1>
              <div style={{width: "100%", textAlign: "center"}}>
                <div style={{position: "relative", margin: "auto", width: "200px", height: "200px"}}>
                  <canvas id="profile-cropper" width="200px" height="200px"/>
                  <canvas id="profile-cropper-overlay" width="1" height="1" style={{position: "absolute", left: "0", top: "0", zIndex: "100"}} />
                </div>
                <br/>
                <canvas id="hidden-canvas" width="1" height="1" style={{display: "none"}}/>
              </div>
              {this.state.sliderVisible ?
                <div style={{width: "50%", margin: "auto"}}>
                  <div className={'slidecontainer'}>
                    {/*<h3>Rotate</h3>
                    <input type="range" min="-90" max="90" step="1" value={this.state.villianRotaterValue} className="round-slider" id="myRotationRange" onChange={(e) => this.sliderChangeHandler(e.target.value, true)}/>
                    <br />*/}
                    <h3>Resize</h3>
                    <input type="range" min="0" max="15" step="0.01" value={this.state.villianSliderValue} className="slider" id="myRange" onChange={(e) => this.sliderChangeHandler(e.target.value)}/>
                  </div>
                </div>
              :
                null
              }
              <br/>
              <button className="main-nav-link" onClick={(e) => {
                  this.changeProfileImage(e)
              }}>Upload An Image</button>
              <br/>
              <input id="profile-image-input" type="file" style={{visibility: "hidden"}} onChange={(e) => {
                this.handleImageSelect(e.target.files[0])
              }
              }/>
              <br/>
              {!this.state.editName ?
                <p style={{display: "inline-block"}}><span style={{fontSize: "1.5rem", paddingRight: "1rem"}}>Name: {this.state.name}</span> <FontAwesomeIcon icon={faPencilAlt} style={{color: "#114596", paddingBottom: "0.4rem", cursor: "pointer"}}
                  onClick={() => {
                    this.setState({editName: true})
                  }}
                />
                </p>
                :
                <>
                <input
                  id="name"
                  className="signup-input-element"
                  type="text"
                  name="name"
                  value={this.state.name}
                  // label="Name"
                  // control="input"
                  onChange={(e) => this.inputChangeHandler("name", e.target.value)}
                  // error={this.state.nameError}
                  // errorMessage="Please Enter Your Name"
                  onClick={() => this.setState({nameError: false})}
                  // onBlur={this.inputBlurHandler.bind(this, 'email')}
                  // value={this.state.signupForm['email'].value}
                  // valid={this.state.signupForm['email'].valid}
                  // touched={this.state.signupForm['email'].touched}
                />
                <br/>
                <br/>
                <button className="main-nav-link" onClick={() => {
                  this.setState({editName: false})
                }}>Better!</button>
                </>
              }
              <br />
              <br />
              {!this.state.editEmail ?
                <p style={{display: "inline-block"}}><span style={{fontSize: "1.5rem", paddingRight: "1rem"}}>Email: {this.state.email}</span> <FontAwesomeIcon icon={faPencilAlt} style={{color: "#114596", paddingBottom: "0.4rem", cursor: "pointer"}}
                  onClick={() => {
                    this.setState({editEmail: true})
                  }}
                />
                </p>
              :
                <>
                  <input
                    id="email"
                    className="signup-input-element"
                    name="email"
                    type="email"
                    value={this.state.email}
                    // label="Email"
                    // control="input"
                    onChange={(e) => this.inputChangeHandler("email", e.target.value)}
                    // error={this.state.emailError}
                    // errorMessage="Please Enter A Valid Email"
                    onClick={() => this.setState({emailError: false})}
                    // onBlur={this.inputBlurHandler.bind(this, 'email')}
                    // value={this.state.signupForm['email'].value}
                    // valid={this.state.signupForm['email'].valid}
                    // touched={this.state.signupForm['email'].touched}
                  />
                  <br/>
                  <br/>
                  <button className="main-nav-link" onClick={() => {
                    this.setState({editEmail: false})
                  }}>Better!</button>
                </>
              }
              <br/>
              <a href="/reset-password">Reset your password?</a>
              <h3>End Date of Plan:</h3>
              <div>{this.state.endDateOfPlan}</div>
              <br/>
              <a href="/secure/cancel">cancel plan</a>
              <br />
              <br />
              {this.state.loading ?
              <div style={{textAlign: "center"}}><Loader
                  type="ThreeDots"
                  color="#0a3f93"
                  height={100}
                  width={100}
               /></div>
               :
              <button
                type="submit"
                className="cta-button"
                onClick={(e)=> {
                  e.preventDefault()
                  this.submitHandler()
                }}
              >
                Save and Continue!
              </button>
            }
            <br/>
            <br/>
            </div>
            <br/>
            <br/>
          </form>
          <div style={{width: "20%"}}></div>
        </div>
        <canvas id="hidden-canvas" width="1" height="1" style={{display: "none"}}/>
      </Layout>
    )
  }
}
export default SignUp
