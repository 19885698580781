import React from 'react';
import { navigate } from 'gatsby';
import { getUser } from '../services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (typeof window !== 'undefined' && !getUser() && location.pathname !== '/secure/login') {
    navigate('/secure/login');
    return null;
  }

  return <Component {...rest} location={location} />;
};

export default PrivateRoute;
