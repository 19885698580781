import React, { Component } from 'react';
// import * as THREE from 'three';
import Loader from 'react-loader-spinner';
import { config } from './config';
// import Walk2 from "../images/walkingMan2.png"
// import Layout from "../components/layout"

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      level1Text: '',
      level2Text: '',
      level3Text: '',
      enemy: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      image5: '',
      image6: '',
      isloading: true,
      isWalking: false,
      walkingRight: true,
    };
    this.startGame = this.startGame.bind(this);
  }

  componentDidMount() {
    // this.startGame() // remove from Here!! only for testing
    fetch(`${config.backend}/game/load-game`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.id,
      }),
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.errors) {
          throw new Error('Fetching post failed!');
        }
        console.log(resData);
        this.setState({
          level1Text: resData.game.level1Text,
          level2Text: resData.game.level2Text,
          level3Text: resData.game.level3Text,
          enemy: resData.game.enemy,
          image1: `${config.backend}/${resData.game.imageone}`,
          image2: `${config.backend}/${resData.game.imagetwo}`,
          image3: `${config.backend}/${resData.game.imagethree}`,
          image4: `${config.backend}/${resData.game.imagefour}`,
          image5: `${config.backend}/${resData.game.imagefive}`,
          image6: `${config.backend}/${resData.game.imagesix}`,
        });
        this.startGame();
        document.addEventListener('keydown', (e) => {
          if (e.key === 'ArrowRight') {
            this.setState({
              isWalking: true,
              walkingRight: true,
            });
          }
          if (e.key === 'ArrowLeft') {
            this.setState({
              isWalking: true,
              walkingRight: false,
            });
          }
        });
        document.addEventListener('keyup', (e) => {
          this.setState({
            isWalking: false,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  startGame() {
    this.setState({ isloading: false });
    // var scene = new THREE.Scene();
    // scene.background = new THREE.Color( 0x87ceeb );
    // var camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
    // var renderer = new THREE.WebGLRenderer();
    // renderer.setSize( window.innerWidth * 0.95, window.innerHeight * 0.95 );
    const container = document.getElementById('pixopixa-custom-player');
    console.log(container.offsetWidth);
    // renderer.setSize( container.offsetWidth - 30, container.offsetHeight - 30 );
    // container.appendChild(renderer.domElement);
    // var loader = new THREE.ImageLoader();
    // loader.load(this.state.image1, (image) => {
    //   var context = container.getContext( '2d' );
    //   context.drawImage( image, 100, 100 );
    // })
    // var spriteMap = new THREE.TextureLoader().load( this.state.image1 );
    // var spriteMaterial = new THREE.SpriteMaterial( { map: spriteMap } );
    // var sprite = new THREE.Sprite( spriteMaterial );
    // var spriteMap2 = new THREE.TextureLoader().load( this.state.image2 );
    // var spriteMaterial2 = new THREE.SpriteMaterial( { map: spriteMap2 } );
    // var sprite2 = new THREE.Sprite( spriteMaterial2 );
    // var spriteMap3 = new THREE.TextureLoader().load( this.state.image3 );
    // var spriteMaterial3 = new THREE.SpriteMaterial( { map: spriteMap3 } );
    // var sprite3 = new THREE.Sprite( spriteMaterial3 );
    // scene.add( sprite );
    // sprite.scale.set(1.5,1.5,1);
    // sprite2.scale.set(1.5,1.5,1);
    // sprite3.scale.set(1.5,1.5,1);

    // camera.position.z = 5;
    const stepDelay = 0;
    const walking1 = true;

    var animate = () => {
      requestAnimationFrame(animate);
      // if (this.state.isWalking && this.state.walkingRight) {
      //   if (stepDelay === 10) {
      //     if (walking1) {
      //       scene.remove(sprite)
      //       scene.remove( sprite2 )
      //       scene.add(sprite3)
      //       stepDelay = 0
      //     } else {
      //       scene.remove(sprite)
      //       scene.remove( sprite3 )
      //       scene.add(sprite2)
      //       stepDelay = 0
      //     }
      //     stepDelay = 0
      //     walking1 = !walking1
      //   } else {
      //     stepDelay += 1
      //     if (stepDelay > 10) {
      //       stepDelay = 0
      //     }
      //   }
      // } else if (this.state.isWalking && !this.state.walkingRight) {
      //   sprite2.scale.x = -1.5
      //   sprite3.scale.x = -1.5
      //   if (stepDelay === 10) {
      //     if (walking1) {
      //       scene.remove(sprite)
      //       scene.remove( sprite2 )
      //       scene.add(sprite3)
      //       stepDelay = 0
      //     } else {
      //       scene.remove(sprite)
      //       scene.remove( sprite3 )
      //       scene.add(sprite2)
      //       stepDelay = 0
      //     }
      //     stepDelay = 0
      //     walking1 = !walking1
      //   } else {
      //     stepDelay += 1
      //     if (stepDelay > 10) {
      //       stepDelay = 0
      //     }
      //   }
      // } else {
      //   scene.remove( sprite2 )
      //   scene.remove( sprite3 )
      //   scene.add( sprite )
      // }
      // renderer.render( scene, camera );
    };

    animate();
  }

  render() {
    return (
      <div>
        { this.state.isloading
          ? (
            <div style={{
              position: 'absolute', zIndex: '100', left: '50vw', top: '70vh',
            }}
            >
              <Loader type="Puff" />
              <p>Loading...</p>
            </div>
          )
          : null}
        <div
          id="pixopixa-custom-player"
          style={{
            margin: 'auto', textAlign: 'center', border: '15px ridge #ce1417', height: '95vh', width: '95vw',
          }}
        />
        <br />
      </div>
    );
  }
}

export default Game;
