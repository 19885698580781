import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { logoutHandler } from '../services/auth';
import { config } from './config';
import Layout from './layout';

class CancelSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionId: '',
      noSubscriptionFound: false,
      loading: false,
      success: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    fetch(`${config.backend}/user/get-subscription-id`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        email: localStorage.getItem('email'),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.subscriptionId) {
          this.setState({
            noSubscriptionFound: true,
          });
          return;
        }
        this.setState({
          subscriptionId: res.subscriptionId,
        });
      // subscriptionId = res.subscriptionId
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          noSubscriptionFound: true,
        });
      });
  }

  handleClick(evt) {
    // let subscriptionId = "1"
    // if (!subscriptionId) return
    evt.preventDefault();
    this.setState({
      loading: true,
    });
    return fetch(`${config.backend}/user/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        subscriptionId: this.state.subscriptionId,
      }),
    })
      .then((response) => {
        if (response.status === 404) {
          this.setState({
            noSubscriptionFound: true,
            loading: false,
          });
          return;
        }
        return response.json();
      })
      .then((res) => {
        this.setState({
          loading: false,
          success: true,
        }, () => {
          setTimeout(() => {
            logoutHandler();
          }, 3000);
        });
        // Display to the user that the subscription has been cancelled.
      })
      .catch((err) => {
        this.setState({
          noSubscriptionFound: true,
        });
      });
  }

  render() {
    return (
      <Layout>
        {!this.state.noSubscriptionFound
          ? (
            <div style={{ textAlign: 'center' }}>
              <div>Do you want to cancel?</div>
              <div>Are you sure?</div>
              <div>I think you are not sure</div>
              <div>Pixopixa is like really nice and the such...</div>
              <div>Maybe you clicked this button by mistake :D</div>
              <div><a href="/">you can click here to go back because of the mistake :D :D :D</a></div>
              <div>Unless it wasn't a mistake :( :( :(</div>
              {this.state.success
                ? (
                  <div style={{
                    backgroundColor: '#4BB543', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid #1e7011', fontWeight: '600', marginBottom: '1rem',
                  }}
                  >
                    Subscription Deleted Successfully!
                  </div>
                )
                : (
                  <div>
                    {this.state.loading
                      ? (
                        <Loader
                          type="Puff"
                          color="#03cea4"
                          height={100}
                          width={100}
                        />
                      )
                      : (
                        <button onClick={this.handleClick} type="submit" className="main-nav-link">
                          Cancel subscription
                        </button>
                      )}
                  </div>
                )}
            </div>
          )
          : (
            <div style={{
              backgroundColor: '#FF4200', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid red', fontWeight: '600', marginBottom: '1rem',
            }}
            >
              Uh Oh! We were unable to find a subscription with the details you logged in with. Please make sure you are connected to the internet and refresh the page. If the problem is on our end email
              <a href="mailto:info@pixopixa.com">info@pixopixa.com</a>
              {' '}
              and we'll sort it out for you right away!
            </div>
          )}
      </Layout>
    );
  }
}

export default CancelSubscription;
