import React, { Component } from 'react';
import { navigate } from 'gatsby';
import '../pages/products.css';
// import Layout from "../components/layout"

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.setState({
      ...this.props.location.state,
    });
  }

  subClick() {
    navigate('/secure/checkout', {
      state: {
        customer: this.state.customer,
        id: this.state.id,
        email: this.state.email,
      },
    });
  }

  singleClick() {
    navigate('/secure/checkout-single', {
      state: {
        customer: this.state.customer,
        id: this.state.id,
        email: this.state.email,
      },
    });
  }

  freeClick() {
    navigate('/secure/profile');
  }

  render() {
    return (
      <div>
        <main>
          <section className="package" id="plus">
            <div style={{ padding: '2rem' }} onClick={() => { this.subClick(); }}>
              <h1 className="package__title">One Year Membership</h1>
              <h2 className="package__badge">RECOMMENDED</h2>
              <h2 className="package__subtitle">
                Our most popular bundle.
                <br />
                <br />
                <span className="strikethrough">Regular $48/year</span>
                <br />
                {' '}
                <span className="package__subtitle--price">Yours for $24/year!</span>
              </h2>
              <p className="package__info">Get 1 Year of unlimited access to all our custom games! Build, play, and send unlimited games for only $2/month! billed annually</p>
            </div>
          </section>
          <section className="package" id="free">
            <div style={{ padding: '2rem' }} onClick={() => { this.freeClick(); }}>
              <h1 className="package__title">Our FREE Plan</h1>
              <h2 className="package__subtitle">Sign up to our newsletter for access to our free tier products.</h2>
              <p className="package__info">Build our free tier games and share the link with your friends!</p>
            </div>
          </section>
          <div className="clearfix" />
          <section className="package" id="premium">
            <div style={{ padding: '2rem' }} onClick={() => { this.singleClick(); }}>
              <h1 className="package__title">Pay Per Game</h1>
              <h2 className="package__subtitle">
                Purchase our premium games individually to share with your friends
                <br />
                <br />
                <span className="package__subtitle--price">Yours for $5/Game!</span>
              </h2>
              <p className="package__info">A great chance to sample our games and purchase only as many as you need to share with friends and family!</p>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Game;
