import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeUp, faVolumeMute, faVolumeDown, faPause, faPlay, faLock, faHome, faExpand, faCompress,
} from '@fortawesome/free-solid-svg-icons';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  TumblrIcon,
  TwitterIcon,
} from 'react-share';
import CreateAccountModal from './create-account-modal';
import Layout from './layout-minimal';
import Audio1 from '../../public/audio/1.mp3';
import CoinSound from '../../public/audio/pickup_coin.wav';
import JumpSound from '../../public/audio/jump.wav';
import {
  tileset1, gameObjects1, tileset2, gameObjects2, tileset3, gameObjects3,
} from './tilesets.js';
import { getSpriteArray } from './game-engine/load-images';
import {
  gameValues, initializeGame, animateGame, initializeMenu,
} from './game-engine/render-game';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: '',
      id: this.props.id,
      level1Text: '',
      level2Text: '',
      level3Text: '',
      enemy: '',
      isloading: true,
      gameVolume: 0.5,
      paused: false,
      gameOver: false,
      menuOpen: true,
      mainMenuOpen: true,
      adjustingVolume: false,
      muted: false,
      canvasWidth: 0,
      canvasHeight: 0,
      currentLevel: 1,
      level2Locked: true,
      level3Locked: true,
      fullScreen: false,
    };
    gameValues.gameSprites = { ...(getSpriteArray(this.props.gameVersionId)) };
    this.startGame = this.startGame.bind(this);
    this.modalCloseHandler = this.modalCloseHandler.bind(this);
    this.setGameValues = this.setGameValues.bind(this);
    this.getGameValues = this.getGameValues.bind(this);
    this.levelMusic = React.createRef();
    this.coinSound = React.createRef();
    this.jumpSound = React.createRef();
  }

  componentDidMount() {
    let imageLoadCount = 0;
    for (const [key, value] of Object.entries(gameValues.gameSprites)) {
      gameValues.gameSprites[key].onload = () => {
        imageLoadCount++;
        if (imageLoadCount === Object.keys(gameValues.gameSprites).length) {
          initializeMenu(this.setGameValues, this.getGameValues, this.props.gameId, this.props.gameVersionId);
        }
      };
    }
  }

  setGameValues(obj, cb) {
    this.setState(obj, cb ? cb() : null);
  }

  getGameValues() {
    return this.state;
  }

  startGame() {
    gameValues.coinSound = this.coinSound;
    gameValues.jumpSound = this.jumpSound;
    gameValues.levelMusic = this.levelMusic;
    initializeGame(this.getGameValues, this.setGameValues);
    const animate = () => {
      if (!this.state.paused) {
        animateGame(this.setGameValues, this.getGameValues);
      }
      if (this.state.menuOpen) return;
      requestAnimationFrame(animate);
    };
    animate();
  }

  fullScreenGame() {
    const el = document.getElementById('canvas-container');
    if (!this.state.fullScreen) {
      if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else {
        el.mozRequestFullScreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  modalCloseHandler(e, modal) {
    if (modal === e.target) {
      this.setState({ show: false });
    }
  }

  render() {
    return (
      <Layout>
        <div style={{ textAlign: 'center', backgroundColor: '#F0F0F0', paddingBottom: '2.5rem' }}>
          <h2 style={{ marginTop: '0', paddingTop: '2.5rem' }}>Get your game link:</h2>
          <div className="tooltip">
            <button className="main-nav-link" onClick={() => this.setState({ show: true })}>
              Copy Link
            </button>
          </div>
          <br />
          <h2>Share via Email:</h2>
          <button className="main-nav-link" onClick={() => this.setState({ show: true })}>
            Send Email
          </button>
          <CreateAccountModal show={this.state.show} handleClose={() => this.setState({ show: false })} modalCloseHandler={this.modalCloseHandler} email={this.state.email} emailChangeHandler={(e) => { this.setState({ email: e.target.value }); }} gameVersionId={this.props.gameVersionId} gameLink={`https://www.pixopixa.com/secure/game/${this.props.gameId}/${this.props.gameVersionId}`} />
          <br />
          <h2>Share on Social Media:</h2>
          <button style={{ border: 'none', backgroundColor: '#F0F0F0', cursor: 'pointer' }} onClick={() => this.setState({ show: true })}><FacebookIcon size={50} round style={{ padding: '0 30px' }} /></button>
          <button style={{ border: 'none', backgroundColor: '#F0F0F0', cursor: 'pointer' }} onClick={() => this.setState({ show: true })}><FacebookMessengerIcon size={50} round style={{ padding: '0 30px' }} /></button>
          {/* An absolute path to an image is required with prop "media" */}
          <button style={{ border: 'none', backgroundColor: '#F0F0F0', cursor: 'pointer' }} onClick={() => this.setState({ show: true })}><PinterestIcon size={50} round style={{ padding: '0 30px' }} /></button>
          <button style={{ border: 'none', backgroundColor: '#F0F0F0', cursor: 'pointer' }} onClick={() => this.setState({ show: true })}><TumblrIcon size={50} round style={{ padding: '0 30px' }} /></button>
          <button style={{ border: 'none', backgroundColor: '#F0F0F0', cursor: 'pointer' }} onClick={() => this.setState({ show: true })}><TwitterIcon size={50} round style={{ padding: '0 30px' }} /></button>
        </div>
        <h1 style={{ textAlign: 'center' }}>Preview Game</h1>
        <audio ref={this.levelMusic} loop>
          <source src={Audio1} type="audio/mp3" />
        </audio>
        <audio ref={this.coinSound}>
          <source src={CoinSound} type="audio/wav" />
        </audio>
        <audio ref={this.jumpSound}>
          <source src={JumpSound} type="audio/wav" />
        </audio>
        <div style={{ textAlign: 'center', position: 'relative' }} id="canvas-container">
          <div style={{
            position: 'absolute', left: '60px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
          }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.fullScreen ? faCompress : faExpand}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.fullScreenGame();
                }}
              />
            </span>
          </div>
          <div style={{
            position: 'absolute', left: '110px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
          }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.setState({
                    paused: false,
                    menuOpen: true,
                    mainMenuOpen: true,
                  });
                }}
              />
            </span>
          </div>
          <div
            title="press &quot;P&quot; to Pause"
            style={{
              position: 'absolute', left: '160px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
            }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.paused ? faPlay : faPause}
                style={{ color: '#114596', padding: '13% 10% 10% 10%' }}
                onClick={() => {
                  this.setState({
                    paused: !this.state.paused,
                  });
                }}
              />
            </span>
          </div>
          <div
            style={{
              position: 'absolute', left: '210px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
            }}
            onMouseOver={() => { this.setState({ adjustingVolume: true }); }}
            onMouseOut={() => { this.setState({ adjustingVolume: false }); }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.muted ? faVolumeMute : this.state.gameVolume > 0.5 ? faVolumeUp : faVolumeDown}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.levelMusic.current.muted = !this.state.muted;
                  this.coinSound.current.muted = !this.state.muted;
                  this.jumpSound.current.muted = !this.state.muted;
                  this.setState({
                    muted: !this.state.muted,
                  });
                }}
              />
            </span>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={this.state.gameVolume}
              onChange={(e) => {
                this.levelMusic.current.volume = this.state.gameVolume;
                this.coinSound.current.volume = this.state.gameVolume;
                this.jumpSound.current.volume = this.state.gameVolume;
                this.setState({ gameVolume: e.target.value });
              }}
              className="volume-slider"
              style={{ verticalAlign: 'middle', marginLeft: '0.3rem', display: this.state.adjustingVolume ? 'inline-block' : 'none' }}
            />
          </div>
          {/* Pause Buttons */}
          <div style={{
            display: this.state.paused ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '30vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>Game Paused</div>
            <button onClick={() => { this.setState({ paused: false }); }} className="game-button">Resume</button>
            <br />
            <button
              onClick={() => {
                this.setState({
                  paused: false,
                  menuOpen: true,
                  mainMenuOpen: true,
                });
              }}
              className="game-button"
            >
              Main Menu
            </button>
          </div>
          {/* Game Over Buttons */}
          <div style={{
            display: this.state.gameOver ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '30vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>Game Over</div>
            <button
              onClick={() => {
                let tileSet; let
                  gameObjects;
                switch (this.state.currentLevel) {
                  case 1:
                    tileSet = [...tileset1];
                    gameObjects = [...gameObjects1];
                    break;
                  case 2:
                    tileSet = [...tileset2];
                    gameObjects = [...gameObjects2];
                    break;
                  case 3:
                    tileSet = [...tileset3];
                    gameObjects = [...gameObjects3];
                    break;
                  default:
                    tileSet = [...tileset1];
                    gameObjects = [...gameObjects1];
                }
                gameValues.tileSet = [...tileSet];
                gameValues.gameObjects = [...gameObjects];
                this.setState({
                  gameOver: false,
                  menuOpen: false,
                }, () => {
                  this.startGame();
                });
              }}
              className="game-button"
            >
              Retry
            </button>
            <br />
            <button
              onClick={() => {
                this.setState({
                  gameOver: false,
                  menuOpen: true,
                  mainMenuOpen: true,
                });
              }}
              className="game-button"
            >
              Main Menu
            </button>
          </div>
          {/* Main Menu Buttons */}
          { this.state.isloading
            ? (
              <div style={{
                position: 'absolute', zIndex: '100', left: '50vw', top: '70vh',
              }}
              >
                <Loader type="Puff" />
                <p>Loading...</p>
              </div>
            )
            : (
              <>
                <div style={{
                  display: this.state.mainMenuOpen ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '15vh', textAlign: 'center', zIndex: '300',
                }}
                >
                  <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>
                    Adventures of
                    {this.state.enemy}
                    {/* get hero name from server and put here */}
                  </div>
                  <button
                    onClick={() => {
                      this.setState({
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 1,
                      });
                      gameValues.tileSet = [...tileset1];
                      gameValues.gameObjects = [...gameObjects1];
                    }}
                    className="game-button"
                  >
                    {this.state.level2Locked ? 'Start' : 'Level 1'}
                  </button>
                  <br />
                  <button
                    onClick={() => {
                      this.setState({
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 2,
                      });
                      gameValues.tileSet = [...tileset2];
                      gameValues.gameObjects = [...gameObjects2];
                    }}
                    className={this.state.level2Locked ? 'game-button-disabled' : 'game-button'}
                    disabled={this.state.level2Locked}
                  >
                    <FontAwesomeIcon icon={faLock} style={{ display: this.state.level2Locked ? 'inline-block' : 'none' }} />
                    {' '}
                    Level 2
                  </button>
                  <br />
                  <button
                    onClick={() => {
                      this.setState({
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 3,
                      });
                      gameValues.tileSet = [...tileset3];
                      gameValues.gameObjects = [...gameObjects3];
                    }}
                    className={this.state.level3Locked ? 'game-button-disabled' : 'game-button'}
                    disabled={this.state.level3Locked}
                  >
                    <FontAwesomeIcon icon={faLock} style={{ display: this.state.level3Locked ? 'inline-block' : 'none' }} />
                    {' '}
                    Level 3
                  </button>
                </div>
              </>
            )}
          {/* Level Text */}
          <div style={{
            display: this.state.levelTextOpen ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '15vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{
              color: '#ffffff', fontSize: '1.3rem', width: '70%', margin: 'auto',
            }}
            >
              {decodeURI(this.state.level1Text)}
            </div>
            <br />
            <button
              onClick={() => {
                this.fullScreenGame();
                this.startGame();
                this.setState({
                  levelTextOpen: false,
                  menuOpen: false,
                }, () => {
                  this.startGame();
                });
              }}
              className="game-button"
            >
              Continue...
            </button>
          </div>

          <canvas className={this.state.fullScreen ? 'full-screen pixopixa-custom-background' : 'pixopixa-custom-background'} id="pixopixa-custom-background" />
          <canvas id="pixopixa-custom-tileset" style={{ display: 'none' }} />
          <canvas id="pixopixa-custom-player" className={`pixopixa-custom-player ${this.state.fullScreen ? 'full-screen' : ''}`} />
          <canvas id="pixopixa-custom-ui" className={`pixopixa-custom-ui ${this.state.fullScreen ? 'full-screen' : ''}`} />
          <canvas id="pixopixa-custom-pause-screen" className={`pixopixa-custom-pause-screen ${this.state.fullScreen ? 'full-screen' : ''}`} style={{ display: (this.state.paused) ? 'inline-block' : 'none' }} />
          <canvas id="pixopixa-custom-menu-screen" className={`pixopixa-custom-menu-screen ${this.state.fullScreen ? 'full-screen' : ''}`} style={{ display: this.state.menuOpen ? 'inline-block' : 'none' }} />
        </div>
        <canvas id="hidden-canvas" width="1" height="1" style={{ display: 'none' }} />
      </Layout>
    );
  }
}

export default Game;
