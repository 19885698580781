import React from 'react';
import { Router } from '@reach/router';
// import Layout from "../components/layout"
import PrivateRoute from '../components/privateRoute';
import Profile from '../components/profile';
import UserSettings from '../components/user-settings';
import CreateProfile from '../components/create-profile';
import Login from '../components/login';
import Checkout from '../components/checkout';
import ChoosePlan from '../components/ChoosePlan';
import CheckoutSingle from '../components/CheckoutSingle';
import Cancel from '../components/Cancel';
import Send from '../components/send';
import SendCupidsGauntlet from '../components/SendCupidsGauntlet';
import SendGame from '../components/send-game';
import Game from '../components/game';
import Game2 from '../components/game2';
import Reset from '../components/reset';

const Secure = () => (
  <div>
    {/* <Layout> */}
    <Router>
      <PrivateRoute path="/secure/profile" component={Profile} />
      <PrivateRoute path="/secure/user-settings" component={UserSettings} />
      <PrivateRoute path="/secure/checkout" component={Checkout} />
      <PrivateRoute path="/secure/checkout-single" component={CheckoutSingle} />
      <PrivateRoute path="/secure/create-profile" component={CreateProfile} />
      <PrivateRoute path="/secure/cancel" component={Cancel} />
      <PrivateRoute path="/secure/choose-plan" component={ChoosePlan} />
      <PrivateRoute path="/secure/send/:gameId/7" gameVersionId="7" component={SendCupidsGauntlet} />
      <PrivateRoute path="/secure/send/:gameId/:gameVersionId" component={Send} />
      <SendGame path="/secure/send-game/:gameId/:gameVersionId" />
      <Login path="/secure/login" />
      <Game path="/secure/game/:gameId/:gameVersionId" />
      {/* <Game2 path="/secure/game2/:id" /> */}
      <Reset path="/secure/reset/:id" />
    </Router>
    {/* </Layout> */}
  </div>
);

export default Secure;
