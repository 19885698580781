import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { setAutoLogout } from '../services/auth';
import Input from './input';
import { config } from './config';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.state = {
      password: '',
      confirmPassword: '',
      userId: '',
      error: false,
      errorMessage: '',
      newPasswordError: false,
      confirmPasswordError: false,
    };
  }

  componentDidMount() {
    console.log(this.props.id);
    fetch(`${config.backend}/user/confirm-reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.id,
      }),
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.errors) {
          console.log(resData);
          this.setState({
            error: true,
            errorMessage: resData.message,
          });
          return;
          // throw new Error('Fetching post failed!');
        }
        this.setState({
          userId: resData.userId,
        });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  inputChangeHandler(id, value) {
    this.setState({
      [id]: value,
    });
  }

  validatePasswords() {
    let errors = false;
    if (this.state.password.length < 5) {
      this.setState({
        newPasswordError: true,
      });
      errors = true;
    }
    if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        confirmPasswordError: true,
      });
      errors = true;
    }
    return !errors;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (!this.validatePasswords()) {
      return;
    }
    fetch(`${config.backend}/user/finish-reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.id,
        password: this.state.password,
        userId: this.state.userId,
      }),
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.errors) {
          console.log(resData);
          this.setState({
            error: true,
            errorMessage: resData.message,
          });
          return;
          // throw new Error('Fetching post failed!');
        }
        console.log(resData);
        localStorage.setItem('token', resData.token);
        localStorage.setItem('userId', resData.userId);
        localStorage.setItem('name', resData.name);
        localStorage.setItem('email', resData.email);
        const remainingMilliseconds = 365 * 24 * 60 * 60 * 1000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds,
        );
        localStorage.setItem('expiryDate', expiryDate.toISOString());
        setAutoLogout(remainingMilliseconds);
        navigate('/secure/profile');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        <form onSubmit={(e) => { this.handleFormSubmit(e); }}>
          <div style={{ padding: '10% 20%', backgroundColor: '#EAB700', border: '15px ridge #ce1417' }}>
            {/* <label htmlFor="password">New Password</label><br/> */}
            <Input
              id="password"
              className="signup-input-element"
              type="password"
              name="password"
              label="New Password"
              control="input"
              onChange={this.inputChangeHandler}
              error={this.state.newPasswordError}
              errorMessage="Password Too Short"
              onClick={() => this.setState({ newPasswordError: false })}
            />
            {/* <input name="password" type="password" onChange={(e) => {this.inputChangeHandler(e, "password")}}/><br/> */}
            <br />
            <Input
              id="confirmPassword"
              className="signup-input-element"
              type="password"
              name="confirmPassword"
              label="Confirm New Password"
              control="input"
              onChange={this.inputChangeHandler}
              error={this.state.confirmPasswordError}
              errorMessage="Passwords Do Not Match"
              onClick={() => this.setState({ confirmPasswordError: false })}
            />
            <br />
            <button type="submit" disabled={this.state.error} className="btn-sign-up-optin">Submit</button>
            <br />
            <br />
            {this.state.error
              ? (
                <div style={{
                  backgroundColor: '#FF4200', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid red', fontWeight: '600', marginBottom: '1rem',
                }}
                >
                  {this.state.errorMessage}
                </div>
              )
              : null}
          </div>
        </form>
      </div>
    );
  }
}

export default Reset;
