import { gameValues } from './render-game';
import { hurtPlayer, updateUI, groundCheck } from './game-events';

export function animateCheckpoint() {
  gameValues.checkpointAnimationCount += 1;
  if (gameValues.checkpointAnimationCount === 10) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-1'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-1'];
    }
  } else if (gameValues.checkpointAnimationCount === 20) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-2'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-2'];
    }
  } else if (gameValues.checkpointAnimationCount === 30) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-3'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-3'];
    }
  } else if (gameValues.checkpointAnimationCount === 40) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-4'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-4'];
    }
  } else if (gameValues.checkpointAnimationCount === 50) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-5'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-5'];
    }
  } else if (gameValues.checkpointAnimationCount === 60) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-6'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-6'];
    }
  } else if (gameValues.checkpointAnimationCount === 70) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-7'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-7'];
    }
  } else if (gameValues.checkpointAnimationCount === 80) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-8'];
    } else if (gameValues.checkPointIsAnimating === '2') {
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-8'];
    }
  } else if (gameValues.checkpointAnimationCount === 90) {
    if (gameValues.checkPointIsAnimating === '1') {
      gameValues.checkPointIsAnimating = null;
      gameValues.checkpointAnimationCount = 0;
      gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-9'];
    } if (gameValues.checkPointIsAnimating === '2') {
      gameValues.checkPointIsAnimating = null;
      gameValues.checkpointAnimationCount = 0;
      gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-9'];
    }
  }
}

export function animateLevelExit() {
  gameValues.levelExitAnimationCount += 1;
  if (gameValues.levelExitAnimationCount === 10) {
    gameValues.currentLevelExit = gameValues.gameSprites['level-exit-2'];
  } else if (gameValues.levelExitAnimationCount === 20) {
    gameValues.currentLevelExit = gameValues.gameSprites['level-exit'];
  } else if (gameValues.levelExitAnimationCount === 30) {
    gameValues.currentLevelExit = gameValues.gameSprites['level-exit-2'];
  } else if (gameValues.levelExitAnimationCount === 40) {
    gameValues.levelExitAnimationCount = 0;
    gameValues.currentLevelExit = gameValues.gameSprites['level-exit'];
    gameValues.levelExitIsAnimating = false;
  }
}

export function animateCannon() {
  gameValues.cannonAnimationCount += 1;
  if (gameValues.cannonAnimationCount === 20) {
    gameValues.currentCannon = gameValues.gameSprites['cannon-2'];
  } else if (gameValues.cannonAnimationCount === 40) {
    gameValues.currentCannon = gameValues.gameSprites['cannon-3'];
  } else if (gameValues.cannonAnimationCount === 60) {
    gameValues.currentCannon = gameValues.gameSprites['cannon-4'];
  } else if (gameValues.cannonAnimationCount === 80) {
    gameValues.cannonAnimationCount = 60;
    gameValues.currentCannon = gameValues.gameSprites['cannon'];
    gameValues.cannonFiring = 2;
  }
}

export function populateObjects(setGameValues) {
  gameValues.gameObjects.forEach((gameObject, index) => {
    if (gameObject.x > (gameValues.cameraX - 150)
    && gameObject.x < (gameValues.cameraX + gameValues.canvas.width + 150)) {
      if (gameObject.object === 'coin') {
        if (gameObject.x - 60 <= gameValues.x
          && gameObject.x + 60 >= gameValues.x
          && gameObject.y - 60 <= gameValues.y
          && gameObject.y + 60 >= gameValues.y) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.coinSound.current ? gameValues.coinSound.current.currentTime = 0 : null;
          gameValues.coinSound.current ? gameValues.coinSound.current.play(): null;
          gameValues.coins += 1;
          updateUI(setGameValues);
          // this.coinSound.current.currentTime = 0
          // this.coinSound.current.play()
          // setGameValues({
          //   coins: getGameValues().coins + 1
          // }, () => {updateUI()})
        } else {
          gameValues.ctx.drawImage(
            gameValues.currentCoin,
            gameObject.x - gameValues.cameraX,
            gameObject.y - gameValues.cameraY,
            60,
            60,
          );
        }
      } else if (gameObject.object === 'enemy-charge') {
        gameObject.x -= 2;
        if (!groundCheck(gameObject.x, gameObject.y, 10, 100, (top) => {
          gameObject.y = (top - 100);
        })) {
        // )) {)) {
        // if(!groundCheck(gameObject.x, gameObject.y, 10, 100, (top) => {
        //   gameObject.y = (top - 100);
        // })) {
          gameObject.y += 2.5;
        }
        if (gameObject.x - 60 <= gameValues.x
          && gameObject.x + 40 >= gameValues.x
          && gameObject.y - 100 >= gameValues.y
          && gameObject.y - 100 <= (gameValues.y + gameValues.jumpSpeed)
          && gameValues.jumpSpeed >= 0) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.isJumping = true;
        } else if (gameObject.x - 60 <= gameValues.x
          && gameObject.x + 60 >= gameValues.x
          && gameObject.y - 80 <= gameValues.y
          && gameObject.y + 80 >= gameValues.y) {
          if (gameValues.invincible) {
            gameValues.gameObjects.splice(index, 1);
          } else {
            hurtPlayer(1, setGameValues);
          }
        }
        gameValues.ctx.drawImage(
          gameValues.currentEnemy,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          100,
          100,
        );
      } else if (gameObject.object === 'enemy-patrol') {
        if (gameObject.right && gameObject.x + 2 < gameObject.max) {
          gameObject.x += 2;
        } else if (!gameObject.right && gameObject.x - 2 > gameObject.min) {
          gameObject.x -= 2;
        } else if ((
          gameObject.right
          && gameObject.x + 2 >= gameObject.max)
          || (!gameObject.right && gameObject.x - 2 <= gameObject.min)) {
          gameObject.right = !gameObject.right;
        }
        if (
          gameObject.x - 60 <= gameValues.x
          && gameObject.x + 40 >= gameValues.x
          && gameObject.y - 100 >= gameValues.y
          && gameObject.y - 100 <= (gameValues.y + gameValues.jumpSpeed)
          && gameValues.jumpSpeed >= 0) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.isJumping = true;
        } else if (
          gameObject.x - 60 <= gameValues.x
          && gameObject.x + 60 >= gameValues.x
          && gameObject.y - 80 <= gameValues.y
          && gameObject.y + 80 >= gameValues.y) {
          if (gameValues.invincible) {
            gameValues.gameObjects.splice(index, 1);
          } else {
            hurtPlayer(1, setGameValues);
          }
        }
        gameValues.ctx.drawImage(
          gameValues.currentEnemy,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          100,
          100,
        );
      } else if (gameObject.object === 'heart') {
        gameValues.ctx.drawImage(
          gameValues.currentHeart,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          50,
          50,
        );
        if (
          gameObject.x - 50 <= gameValues.x
          && gameObject.x + 50 >= gameValues.x
          && gameObject.y - 50 <= gameValues.y
          && gameObject.y + 50 >= gameValues.y) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.coinSound.current ? gameValues.coinSound.current.currentTime = 0 : null;
          gameValues.coinSound.current ? gameValues.coinSound.current.play() : null;
          const newHealth = gameValues.health < 6 ? gameValues.health + 1 : 6;
          gameValues.health = newHealth;
          updateUI(setGameValues);
          // this.coinSound.current.currentTime = 0
          // this.coinSound.current.play()
          // let newHealth = getGameValues().health < 6 ? getGameValues().health + 1 : 6
          // setGameValues({health: newHealth}, () => {
          //   updateUI()
          // })
        }
      } else if (gameObject.object === 'life') {
        gameValues.ctx.drawImage(
          gameValues.currentLife,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          50,
          50,
        );
        if (
          gameObject.x - 50 <= gameValues.x
          && gameObject.x + 50 >= gameValues.x
          && gameObject.y - 50 <= gameValues.y
          && gameObject.y + 50 >= gameValues.y) {
          gameValues.coinSound.current ? gameValues.coinSound.current.currentTime = 0 : null;
          gameValues.coinSound.current ? gameValues.coinSound.current.play() : null;
          const newLives = gameValues.lives < 5 ? gameValues.lives + 1 : 5;
          gameValues.lives = newLives;
          gameValues.uICtx.clearRect(gameValues.canvas.width - 180, 0, 180, 180);
          gameValues.uICtx.drawImage(
            gameValues.gameSprites.heroImage,
            gameValues.canvas.width - 180,
            7,
            100,
            100,
          );
          gameValues.uICtx.fillText(`x ${gameValues.lives}`, gameValues.canvas.width - 115, 45);
          gameValues.gameObjects.splice(index, 1);
          // this.coinSound.current.currentTime = 0
          // this.coinSound.current.play()
          // let newLives = getGameValues().lives < 5 ? getGameValues().lives + 1 : 5
          // setGameValues({lives: newLives}, () => {
          //   uICtx.clearRect(canvas.width - 180, 0, 180, 180)
          //   uICtx.drawImage(image6, canvas.width - 180, 7, 100, 100)
          //   uICtx.fillText(`x ${getGameValues().lives}`, canvas.width - 115, 45);
          // })
        }
      } else if (gameObject.object === 'fire') {
        gameValues.ctx.drawImage(
          gameValues.currentFire,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          80,
          80,
        );
        if (
          gameObject.x - 40 <= gameValues.x
          && gameObject.x + 40 >= gameValues.x
          && gameObject.y - 80 <= gameValues.y
          && gameObject.y + 80 >= gameValues.y) {
          if (!gameValues.invincible) {
            hurtPlayer(1, setGameValues);
          }
        }
      } else if (gameObject.object === 'bonusBox') {
        if (
          gameObject.x - 41 <= gameValues.x
          && gameObject.x + 41 >= gameValues.x
          && gameObject.y + 81 <= gameValues.y
          && gameValues.y + gameValues.jumpSpeed <= gameObject.y + 81
          && gameValues.jumpSpeed < 0) {
          gameValues.jumpSpeed = 6;
          gameValues.boxBounce = 15;
          const boxBonusIndex = gameValues.gameObjects.forEach((value, index) => {
            if (value.x === gameObject.x && value.object === gameObject.bonus) value.active = true;
          });
          // console.log(gameObjects[boxBonusIndex].active)
          // gameValues.gameObjects[boxBonusIndex].active = true;
        } else if (
          gameObject.x - 75 <= gameValues.x
          && gameObject.x + 75 >= gameValues.x
          && gameValues.y < gameObject.y + 81
          && gameValues.y + 110 > gameObject.y) {
          gameValues.isWalking = false;
        }
        if (gameValues.boxBounce > 0) {
          gameValues.boxBounce -= 3;
        }
        if (!gameObject.pushed) {
          gameValues.groundLikeObjectsInFrame.push(gameObject);
          gameObject.pushed = true;
          gameObject.indexInFrame = gameValues.groundLikeObjectsInFrame.length - 1;
        }
        gameValues.ctx.drawImage(gameValues.bonusBox, gameObject.x - gameValues.cameraX, gameObject.y - gameValues.boxBounce - gameValues.cameraY, gameValues.boxWidth, gameValues.boxHeight);
      } else if (gameObject.object === 'boxCoin' && gameObject.active) {
        gameValues.ctx.drawImage(
          gameValues.currentCoin,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          60,
          60,
        );
        gameObject.x += gameObject.sideBounce;
        if (!groundCheck(gameObject.x, gameObject.y, gameObject.jumpSpeed, 60, (top) => {
          gameObject.y = (top - 60);
        })) {
        // if (!groundCheck(gameObject.x, gameObject.y, gameObject.jumpSpeed, 60, (top) => {
        //   gameObject.y = (top - 60);
        // } )) {
          gameObject.y += gameObject.jumpSpeed;
          gameObject.jumpSpeed += 0.85;
        }
        if (
          gameObject.x - 30 <= gameValues.x
          && gameObject.x + 30 >= gameValues.x
          && gameObject.y - 60 <= gameValues.y
          && gameObject.y + 60 >= gameValues.y) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.coins += 1;
          updateUI(setGameValues);
          gameValues.coinSound.current ? gameValues.coinSound.current.currentTime = 0 : null;
          gameValues.coinSound.current ? gameValues.coinSound.current.play() : null;
          // setGameValues({
          //   coins: this.state.coins + 1
          // }, () => {updateUI()})
        }
      } else if (gameObject.object === 'boxPineapple' && gameObject.active) {
        gameValues.ctx.drawImage(
          gameValues.pineapple,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          60,
          60,
        );
        gameObject.x += gameObject.sideBounce;
        if (!groundCheck(gameObject.x, gameObject.y, gameObject.jumpSpeed, 60, (top) => {
          gameObject.y = (top - 60);
        })) {
        // if (!groundCheck(gameObject.x, gameObject.y, gameObject.jumpSpeed, 60, (top) => {
        //   gameObject.y = (top - 60);
        // } )) {
          gameObject.y += gameObject.jumpSpeed;
          gameObject.jumpSpeed += 0.85;
        }
        if (
          gameObject.x - 30 <= gameValues.x
          && gameObject.x + 30 >= gameValues.x
          && gameObject.y - 60 <= gameValues.y
          && gameObject.y + 60 >= gameValues.y) {
          gameValues.gameObjects.splice(index, 1);
          gameValues.coinSound.current ? gameValues.coinSound.current.currentTime = 0 : null;
          gameValues.coinSound.current ? gameValues.coinSound.current.play() : null;
          if (!gameObject.invincible) {
            gameValues.invincible = true;
            setTimeout(() => {
              gameValues.invincible = false;
            }, 10000);
          }
        }
      } else if (gameObject.object === 'cannon') {
        gameValues.ctx.drawImage(
          gameValues.currentCannon,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          250,
          250,
        );
        if (
          gameObject.x - 100 <= gameValues.x
          && gameObject.x + 100 >= gameValues.x
          && gameObject.y - 100 <= gameValues.y
          && gameObject.y + 100 >= gameValues.y) {
          if (!gameValues.cannonFiring) {
            gameValues.canWalk = false;
            gameValues.canJump = false;
            gameValues.cannonFiring = 1;
            gameValues.cannonXVelocity = gameObject.xVelocity;
            gameValues.cannonYVelocity = gameObject.yVelocity;
            animateCannon();
            // animateCannon()
          }
        }
      } else if (gameObject.object === 'checkpoint1') {
        gameValues.ctx.drawImage(
          gameValues.currentCheckpoint1,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          150,
          150,
        );
        if (
          gameObject.x + 80 >= gameValues.x
          && gameObject.x <= gameValues.x
          && gameValues.y <= gameObject.y + 150) {
          if (gameValues.respawnX < gameObject.x) {
            gameValues.respawnX = gameObject.x;
            gameValues.respawnY = gameObject.y;
            gameValues.checkPointIsAnimating = '1';
          }
        }
      } else if (gameObject.object === 'checkpoint2') {
        gameValues.ctx.drawImage(
          gameValues.currentCheckpoint2,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          150,
          150,
        );
        if (
          gameObject.x + 80 >= gameValues.x
          && gameObject.x <= gameValues.x
          && gameValues.y <= gameObject.y + 150) {
          if (gameValues.respawnX < gameObject.x) {
            gameValues.respawnX = gameObject.x;
            gameValues.respawnY = gameObject.y;
            gameValues.checkPointIsAnimating = '2';
          }
        }
      } else if (gameObject.object === 'levelExit') {
        gameValues.ctx.drawImage(
          gameValues.currentLevelExit,
          gameObject.x - gameValues.cameraX,
          gameObject.y - gameValues.cameraY,
          150,
          150,
        );
        if (
          gameObject.x + 80 >= gameValues.x
          && gameObject.x <= gameValues.x
          && gameValues.y <= gameObject.y + 150) {
          if (gameValues.respawnX < gameObject.x) {
            if (gameValues.currentLevel === 1) {
              gameValues.levelExitIsAnimating = true;
              setGameValues({
                level2Locked: false,
              });
              localStorage.setItem('level2Unlocked', true);
            } else if (gameValues.currentLevel === 2) {
              gameValues.levelExitIsAnimating = true;
              setGameValues({
                level3Locked: false,
              });
              localStorage.setItem('level3Unlocked', true);
            }
          }
        }
      }
    } else if (gameObject.object === 'moving-platforms' && (gameValues.cameraX + gameValues.canvas.width + 150 > gameObject.min && gameValues.cameraX < gameObject.max)) {
      if (!gameObject.pushed) {
        gameValues.groundLikeObjectsInFrame.push(gameObject);
        gameObject.pushed = true;
        gameObject.indexInFrame = gameValues.groundLikeObjectsInFrame.length - 1;
      }
      gameObject.platforms.forEach((platform) => {
        if (platform.object === 'moving-platform') {
          if (platform.right && platform.x + 3.5 < platform.max) {
            platform.x += 3.5;
            if (gameValues.stuckToPlatform.x === platform.x) {
              gameValues.cameraSpeed = 3.5;
              gameValues.x += 3.5;
              // cameraSpeed = 3.5;
              // x += 3.5
            }
          } else if (!platform.right && platform.x - 3.5 > platform.min) {
            platform.x -= 3.5;
            if (gameValues.stuckToPlatform.x === platform.x) {
              gameValues.cameraSpeed = 3.5;
              gameValues.x -= 3.5;
              // cameraSpeed = 3.5;
              // x -= 3.5
            }
          } else if (
            (platform.right && platform.x + 3.5 >= platform.max)
            || (!platform.right && platform.x - 3.5 <= platform.min)) {
            platform.right = !platform.right;
          }
          if (
            platform.x > (gameValues.cameraX - 150)
            && platform.x < (gameValues.cameraX + gameValues.canvas.width + 150)) {
            gameValues.ctx.drawImage(
              gameValues.currentMovingPlatformLeft,
              platform.x - gameValues.cameraX,
              platform.y - gameValues.cameraY,
              80,
              80,
            );
            gameValues.ctx.drawImage(
              gameValues.currentMovingPlatformRight,
              platform.x - gameValues.cameraX + 80,
              platform.y - gameValues.cameraY,
              80,
              80,
            );
          }
        } else if (platform.object === 'mossy-moving-platform') {
          if (platform.right && platform.x + 3.5 < platform.max) {
            platform.x += 3.5;
          } else if (!platform.right && platform.x - 3.5 > platform.min) {
            platform.x -= 3.5;
          } else if (
            (platform.right && platform.x + 3.5 >= platform.max)
            || (!platform.right && platform.x - 3.5 <= platform.min)) {
            platform.right = !platform.right;
          }
          if (
            platform.x > (gameValues.cameraX - 150)
            && platform.x < (gameValues.cameraX + gameValues.canvas.width + 150)) {
            gameValues.ctx.drawImage(
              gameValues.currentMossyMovingPlatformLeft,
              platform.x - gameValues.cameraX,
              platform.y - gameValues.cameraY,
              80,
              80,
            );
            gameValues.ctx.drawImage(
              gameValues.currentMossyMovingPlatformRight,
              platform.x - gameValues.cameraX + 80,
              platform.y - gameValues.cameraY,
              80,
              80,
            );
          }
        }
      });
    } else if ((gameObject.object === 'bonusBox' || gameObject.object === 'moving-platforms') && ((gameObject.x < (gameValues.cameraX - 150) || gameObject.x > (gameValues.cameraX + gameValues.canvas.width + 150)))) {
      if (gameObject.pushed) {
        gameValues.groundLikeObjectsInFrame.splice(gameObject.indexInFrame, 1);
        gameValues.gameObjects.forEach((object) => {
          if (object.indexInFrame && object.indexInFrame > gameObject.indexInFrame) {
            object.indexInFrame -= 1;
          }
        });
        gameObject.pushed = false;
        gameObject.indexInFrame = null;
      }
    }
  });
}
