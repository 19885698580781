import React from 'react';
import { navigate } from 'gatsby';
import GetEmailCopy from './get-email-copy';
import ImgNextGen from './ImgNextGen';
import config from './config';

const CreateAccountModal = ({
  handleClose, show, modalCloseHandler, email, emailChangeHandler, gameVersionId, gameLink,
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName} id="modal-container" onClick={(e) => { modalCloseHandler(e, document.getElementById('modal-container')); }}>
      <section className="modal-main" id="email-popup" style={{ border: '4px solid #0a3f93' }}>
        <div style={{ minHeight: '500px', marginBottom: '0', position: 'relative' }}>
          <button onClick={handleClose} className="btn-optin-close">x</button>
          <div style={{
            display: 'flex', justifyContent: 'center', backgroundColor: '#00BCD4', padding: '20px 0',
          }}
          >
            <ImgNextGen width="60px" height="60px" fallback={`${config.img}pixopixa-logo-new.png`} srcWebp={`${config.img}pixopixa-logo-new.webp`} />
            <div style={{ width: '30px' }} />
            <ImgNextGen width="80px" height="60px" fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} />
          </div>
          <h1 className="filled-text-blue" style={{ fontSize: '3rem', width: '80%', margin: '2rem auto' }}>Please Create an Account to Access and Share Your Game</h1>
          <button className="cta-button" onClick={() => navigate('/sign-up')}>OK, Cool</button>
          <p>
            already have an account?
            <a href="/secure/login">Login here</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default CreateAccountModal;
