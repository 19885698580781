import React from 'react';
import GetEmailCopy from './get-email-copy';
import ImgNextGen from './ImgNextGen';
import config from './config';

const EmailModal = ({
  handleClose, show, modalCloseHandler, email, emailChangeHandler, gameVersionId, gameLink,
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName} id="modal-container" onClick={(e) => { modalCloseHandler(e, document.getElementById('modal-container')); }}>
      <section className="modal-main" id="email-popup" style={{ border: '4px solid #0a3f93' }}>
        <div style={{ minHeight: '600px', marginBottom: '0' }}>
          <button onClick={handleClose} className="btn-optin-close">x</button>
          <br />
          Send To:
          {' '}
          <input value={email} className="send-url-input" id="emailLink" onChange={(e) => { emailChangeHandler(e); }} onClick={() => { document.getElementById('email-error-message').classList.add('hidden'); }} />
          <br />
          <div
            id="email-error-message"
            className="hidden"
            style={{
              backgroundColor: '#FF4200', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid red', width: '100%', fontWeight: '600', marginBottom: '1rem',
            }}
          >
            Please Enter a Valid Receiver's Email
          </div>
          <br />
          <br />
          <div style={{
            display: 'flex', justifyContent: 'center', backgroundColor: '#00BCD4', padding: '20px 0',
          }}
          >
            <ImgNextGen width="60px" height="60px" fallback={`${config.img}pixopixa-logo-new.png`} srcWebp={`${config.img}pixopixa-logo-new.webp`} />
            <div style={{ width: '30px' }} />
            <ImgNextGen width="80px" height="60px" fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} />
          </div>
          <div>
            <GetEmailCopy
              gameVersionId={gameVersionId}
              gameLink={gameLink}
              receiverEmail={email}
              emailError={() => {
                document.getElementById('email-error-message').classList.remove('hidden');
                document.getElementById('email-popup').scrollTo(0, 0);
              }}
            />
          </div>
          <br />
          <br />
        </div>
      </section>
    </div>
  );
};

export default EmailModal;
