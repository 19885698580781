import React from "react"
import { navigate } from "gatsby"
import { loginHandler, getUser } from "../services/auth"
import { validateEmail } from "../util/validators.js"
import Input from '../components/input';
import Loader from 'react-loader-spinner'
import Layout from "../components/layout-minimal"
import ImgNextGen from "../components/ImgNextGen"
import Image from "../../public/images/login-feature.png"
import Image2 from "../../public/images/login-feature2.png"

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    emailError: false,
    passwordError: false,
    loginFailed: false,
    loginFailedMessage: "",
    loading: false,
    loggedIn: false,
  }

  componentDidMount() {
    if (getUser()) {
      this.setState({
        loggedIn: true
      })
    }
  }

  handleUpdate = (id, value) => {
    this.setState({
      [id]: value,
    })
  }

  validateResponses = () => {
    let hasErrors = false
    let emailError = false
    let passwordError = false
    if (!validateEmail(this.state.email)) {
      emailError = true
      hasErrors = true
    }
    if (this.state.password.length < 5) {
      passwordError = true
      hasErrors = true
    }
    this.setState({
      emailError: emailError,
      passwordError: passwordError,
    })
    return !hasErrors ? true : false
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({loading: true})
    if (this.validateResponses()) {
      loginHandler(this.state, (message = "login Unsuccessful") => {
        this.setState({
          loginFailedMessage: message
        })
        window.scrollTo(0, 0)
        this.setState({loading: false})
      })
    }
  }

  render() {
    if (this.state.loggedIn) {
      navigate(`/secure/profile`)
    }

    return (
      <Layout>
        <div style={{display: "flex", justifyContent: "center", backgroundColor: "#03cea4", height: "100vh"}}>
          <div style={{width: "46%", textAlign: "center", backgroundColor: "#ffffff", margin: "0 2%", borderRadius: "20px", padding: "3% 0"}}>
            <h1>Log in</h1>
            <form
              method="post"
              onSubmit={event => {
                this.handleSubmit(event)
                // navigate(`/secure/profile`)
              }}
            >
            <div>
              {this.state.loginFailedMessage ? <div style={{backgroundColor: "#FF4200", color: "#ffffff", padding: "0.5rem", fontSize: "1rem", border: "1px solid red", width: "100%", fontWeight: "600", marginBottom: "1rem" }}>{this.state.loginFailedMessage}</div> : null}
                <br />
                <input
                  id="email"
                  className="signup-input-element"
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  // control="input"
                  onChange={(e) => this.handleUpdate("email", e.target.value)}
                  // error={this.state.emailError}
                  // errorMessage="Please Enter A Valid Email"
                  onClick={() => this.setState({emailError: false})}
                />
                <br />
                <br />
                <input
                  id="password"
                  className="signup-input-element"
                  placeholder="Password"
                  type="password"
                  name="password"
                  // label="Password"
                  // control="input"
                  onChange={(e) => this.handleUpdate("password", e.target.value)}
                  // error={this.state.passwordError}
                  // errorMessage="Password Too Short"
                  onClick={() => this.setState({passwordError: false})}
                />
                <br />
                <br />
                {this.state.loading ?
                  <div style={{textAlign: "center"}}><Loader
                      type="Bars"
                      color="#03cea4"
                      height={100}
                      width={100}
                   /></div>
                   :
                  <button type="submit" className="cta-button">Log In</button>
                }
                <br/>
                <br/>
                <div style={{textAlign: 'center'}}>
                  <a href="/ResetPassword">Forgot Your Password?</a>
                </div>
              </div>
            </form>
          </div>
          <div style={{width: "50%", padding: "0 5%", overflowY: "scroll"}} className="hide-scrollbar">
            <ImgNextGen width="100%" fallback={Image} margin="0 0 -6px 0" /*srcWebp={config.img + "pixopixa-logo-1.webp"}*//>
            <ImgNextGen width="100%" fallback={Image2} /*srcWebp={config.img + "pixopixa-logo-1.webp"}*//>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
